<template>
  <Loading v-if="isLoading || !data" />
  <div v-else class="edit-delivery-boy">
    <b-card>
      <div class="p-2 mb-1">
        <h1 class="m-0 page-title">
          {{ $t("delivery.delivery_infromation") }}
        </h1>
      </div>
      <hr />
      <b-form @submit.prevent>
        <h3 class="sub-title mb-3">
          {{ $t("delivery.delivery_boy_information") }}
        </h3>
        <ul class="vendor-info" v-if="data">
          <li>
            <span>{{ $t("g.id_number") }}:</span>
            <strong>{{
              data.user.id_number ? data.user.id_number : $t("notFound")
            }}</strong>
          </li>
          <li>
            <span>{{ $t("g.id_residence_expiry_date") }}:</span>
            <strong>{{
              data.user.id_expiry_date
                ? data.user.id_expiry_date
                : $t("notFound")
            }}</strong>
          </li>
          <li>
            <span>{{ $t("g.id_residence_number_image") }}:</span>
            <strong>
              <a v-if="idImage" :href="idImage" target="_blank">{{
                $t("g.view_file")
              }}</a>
              <span v-else>{{ $t("g.id_image_not_found") }}</span>
            </strong>
          </li>
          <li>
            <span>{{ $t("g.driving_license_expiry_date") }}:</span>
            <strong>{{
              data.licence_expiry_date
                ? data.licence_expiry_date
                : $t("notFound")
            }}</strong>
          </li>
          <li>
            <span>{{ $t("g.driving_license_image") }}:</span>
            <strong>
              <a v-if="licenseImage" :href="licenseImage" target="_blank">{{
                $t("g.view_file")
              }}</a>
              <span v-else>{{ $t("notFound") }}</span>
            </strong>
          </li>
        </ul>
        <hr />
        <b-row>
          <b-col md="6">
            <b-form-group :label="$t('delivery.name')" label-for="Name">
              <b-form-input
                v-model="form_data.name"
                disabled
                size="sm"
              ></b-form-input>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group :label="$t('delivery.shift')" label-for="Shift">
              <b-form-select
                v-model="form_data.shift_id"
                :options="shfit"
                value-field="id"
                text-field="name"
                size="sm"
              ></b-form-select>
            </b-form-group>
          </b-col>
          <!-- <b-col md="6" >
            <b-form-group :label="$t('delivery.shift_time')">
              <b-form-datepicker v-model="form_data.start_date" />
            </b-form-group>
          </b-col> -->
          <!-- <b-col md="6">
            <b-form-group :label="$t('delivery.delivery_area')">
              <b-form-select v-model="form_data.type" :options="state">
              </b-form-select>
            </b-form-group>
          </b-col>

          <b-col md="6">
            <div class="box">
              <div class="item">
                <span>{{ $t("delivery.orders_in_last_day") }}</span>
                <span class="number">{{ 20 }}</span>
              </div>
              <div class="item">
                <span>{{ $t("delivery.orders_in_last_month") }}</span>
                <span class="number">{{ 20 }}</span>
              </div>
            </div>
          </b-col> -->
        </b-row>

        <b-form-checkbox
          switch
          v-model="form_data.is_active"
          :value="1"
          :unchecked-value="0"
        >
          {{ $t("delivery.enable_the_delivery_boy") }}
        </b-form-checkbox>
        <b-button
          type="submit"
          variant="primary"
          block
          @click="EditDeliveryBoyForm"
          class="primary-btn my-3"
        >
          <span>{{ $t("delivery.save_changes") }}</span>
        </b-button>
      </b-form>
    </b-card>
  </div>
</template>

<script>
import {
  BFormGroup,
  BFormInput,
  BForm,
  BButton,
  BFormSelect,
  BRow,
  BCol,
  BFormDatepicker,
  BFormCheckbox,
  BInputGroup,
  BCard,
} from "bootstrap-vue";
import Loading from "@/components/shared/loading/loading.vue";
import { ValidationObserver, ValidationProvider } from "vee-validate";
import { email, required } from "@core/utils/validations/validations";
export default {
  name: "EditDeliveryBoy",
  components: {
    BFormGroup,
    BFormInput,
    BForm,
    BButton,
    BFormSelect,
    BRow,
    BCol,
    BFormDatepicker,
    BFormCheckbox,
    BInputGroup,
    ValidationObserver,
    ValidationProvider,
    Loading,
    BCard,
  },
  computed: {
    idImage() {
      return this.data.user?.media?.filter(
        (media) => "id_number_image" === media.name
      )[0]?.path;
    },
    licenseImage() {
      return this.data.user?.media?.filter(
        (img) => "driving_license_image" === img.name
      )[0]?.path;
    },
  },
  data() {
    return {
      isLoading: true,
      current_id: null,
      form_data: {
        user_id: null,
        shift_id: null,
        is_active: true,
        city_id: null,
      },
      shfit: [],
      state: [],
      users: null,
      data: null,
      cities: [],
    };
  },

  created() {
    this.current_id = this.$route.params.id;
    this.getBoyData();
    this.getUsersData();
    this.getShiftData();
    this.getCitiesAndStates();
    this.isLoading = false;
  },
  methods: {
    async getCitiesAndStates() {
      try {
        this.isLoading = true;
        const [citiesRequest, stateRequest] = Promise.all([
          this.$http.get("admin/countries/195/cities"),
        ]);
        if (
          [200, 201].includes(citiesRequest.status) &&
          [200, 201].includes(stateRequest.status)
        ) {
          this.cities = citiesRequest.data.data;
        }
      } catch (error) {
        this.$helpers.handleError(error);
      } finally {
        this.isLoading = false;
      }
    },
    getBoyData() {
      this.$http
        .get(`admin/delivery-boys/${this.current_id}`)
        .then((res) => {
          if (res.status === 200) {
            const data = res.data.data;
            this.data = data;
            this.form_data.user_id = data?.user?.id;
            this.form_data.shift_id = data.shift?.id;
            this.form_data.is_active = +data.is_active;
            this.form_data.name = `${data.user.first_name} ${data.user.last_name}`;
            this.isLoading = false;
          }
        })
        .catch((err) => {
          this.$helpers.handleError(err);
        });
    },
    getUsersData() {
      this.$http
        .get(`admin/users`)
        .then((res) => {
          if (res.status === 200) {
            this.users = res.data.data;
            this.isLoading = false;
          }
        })
        .catch((err) => {
          this.$helpers.handleError(err);
        });
    },
    getShiftData() {
      this.$http
        .get(`admin/shifts`)
        .then((res) => {
          if (res.status === 200) {
            this.shfit = res.data.data;
            this.isLoading = false;
          }
        })
        .catch((err) => {
          this.$helpers.handleError(err);
        });
    },
    EditDeliveryBoyForm() {
      const formData = new FormData();
      for (const key in this.form_data) {
        formData.append(key, this.form_data[key]);
      }

      this.$http
        .post(`admin/delivery-boys/${this.current_id}?_method=put`, formData)
        .then((res) => {
          if (res.status === 200) {
            this.$helpers.makeToast(
              "success",
              res.data.message,
              res.data.message
            );
            setTimeout(() => {
              this.$router.push({ name: "delivery-boys-list" });
            }, 1000);
            this.isPending = false;
          }
        })
        .catch((err) => {
          this.$helpers.handleError(err);
          this.isPending = false;
        });
    },
    async getState() {
      try {
        this.isLoading = true;
        const response = await this.$http.get("admin/countries/194/states");
        this.state = response.data.data;
      } catch (err) {
        this.$helpers.handleError(err);
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>

<style lang="scss">
@import "./index";
</style>
